import { useRouter } from "next/router"
import {PiSealCheckDuotone} from "react-icons/pi";
import { lang } from "@/lang/langT";
import usePrice from "@/libs/usePrice";
import { useEffect, useState } from "react";
import Utils from "@/utils/utils";
import axios from "axios";
import {toast} from "react-toastify";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, useDisclosure} from "@nextui-org/react";
import {useAppContext} from "@context/index";

const PricingCard = ({userPlan, expireTime = null, onHome = true, isBought = false, onClick}) => {
    const {name, description, pricing, items, details } = userPlan;
    const { locale, push } = useRouter();
    const t = lang(locale);
    const priceManager = usePrice();
    const [selected, setSelected] = useState(false);
    const [period, setPeriod] = useState('months'); //year
    const {isOpen, onOpen, onOpenChange} = useDisclosure();
    const {basket} = useAppContext();

    useEffect(()=>{
        setSelected((basket||[]).filter(b => b._id === userPlan.key).length > 0);
    },[basket])

    const handleSubmit = (e) => {
        e.preventDefault()
    
        const name = e.target.name.value
        const lastname = e.target.lastname.value
        const email = e.target.email.value
        const message = e.target.message.value
    
        const data = {
          name,
          lastname,
          email,
          message,
        }
    
        axios.post('/api/contact', data)
          .then(res => {
            toast("Message sent.", {
              type: "success",
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
              draggable: true,
              progress: undefined,
    
            })
          })
          .catch(err => {
            toast("Message cannot sent.", {
              type: "error",
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              theme: "dark",
              draggable: true,
              progress: undefined,
            })
          })
        e.target.reset()
      }

    return (
        <>
        <div
            className="w-full flex flex-col p-6 mx-auto max-w-lg text-center text-zinc-900 bg-white rounded-lg border border-zinc-100 shadow dark:border-zinc-600 xl:p-8 dark:bg-zinc-800 dark:text-white">
            <h3 className="mb-4 text-2xl font-semibold">{Utils.nestedObjectString(t, name) || name}</h3>
            <p
                className="font-light text-zinc-500 sm:text-lg dark:text-zinc-400">
                {Utils.nestedObjectString(t, description) || description}
            </p>
            {pricing[period].price === 0 ? 
            <div className="flex justify-center items-baseline my-8">
                <span className="mr-2 text-4xl font-extrabold">-</span>
            </div>
            :
            <div className="flex flex-col justify-center items-center">
                {pricing[period].originalPrice ? <span className="mb-[2px] mr-2 text-3xl font-extrabold"><del>{priceManager.getPrice(pricing[period].originalPrice)}</del></span> : null}
                <div className="flex justify-center items-baseline mb-4">
                    <span className="mr-2 text-4xl font-extrabold">{priceManager.getPrice(pricing[period].price)}</span>
                    <span className="text-zinc-500 dark:text-zinc-400">/ {t.pricingPage.period[period]}</span>
                </div>
                <div className="flex flex-row mb-5">
                    <span style={{
                        width: '100px',
                        borderBottom: `2px solid ${period === 'months' ? '#0070ef':'#fff'}`,
                    }} className="pb-[8px] cursor-pointer" onClick={()=>{
                        onClick && onClick(userPlan, period, false);
                        setSelected(false);
                        setPeriod('months');
                    }}>{t.pricingPage.period['months']}</span>
                    <span style={{
                        width: '100px',
                        borderBottom: `2px solid ${period === 'year' ? '#0070ef':'#fff'}`,
                    }} className="pb-[8px] cursor-pointer" onClick={()=>{
                        onClick && onClick(userPlan, period, false);
                        setSelected(false);
                        setPeriod('year');
                    }}>{t.pricingPage.period['year']}</span>
                </div>
            </div>}
            {/* List */}
            {((items ||{})[locale] ||[]).length > 0 ? 
                <ul role="list" className="mb-8 space-y-4 text-left">
                    {items[locale].map((item, index) => 
                        <li key={item} className="flex items-center space-x-3">
                        {/* Icon */}
                            <PiSealCheckDuotone fontSize={24}
                                                className="flex-shrink-0  text-green-500 dark:text-green-400"/>
                            <span>{item}</span>
                            {index === 0 && details.length > 0 ? <img src="/info-icon-white.svg" className="cursor-pointer" onClick={onOpen} /> : null}
                        </li>
                    )}
                </ul>
            : null}
            <Button style={{
                marginTop:'auto',
                whiteSpace: 'break-spaces'
            }} color={!onHome && (selected || isBought) ? "success" : "primary"} fullWidth={true} className={"rounded"} onClick={()=>{
                if(isBought) {
                    return;
                }
                if(onHome) {
                    push('/feed/pricing');
                    return;
                }
                if(pricing[period].price === '-') {
                    onOpen();
                    return;
                }
                onClick && onClick(userPlan, period, !selected);
                setSelected(!selected);
            }}>
                {onHome ? t.pricingPage.getStarted : (isBought ? `${t.pricingPage.bought}${expireTime ? "\n"+Utils.dateToCountdownString(expireTime, locale, true) : ''}` : (pricing[period].price === '-' ? t.pricingPage.contact : (selected ? t.pricingPage.selected : t.pricingPage.select)))}
            </Button>
        </div>
        <Modal isOpen={isOpen} onOpenChange={onOpenChange}>
            <ModalContent>
            {(onClose) => (
                <>
                <ModalHeader>
                    <p className="px-4 pt-[36px] mb-8 space-y-4">
                        {pricing[period].price === '-' ? t.pricingPage.contact : t.details}
                    </p>
                </ModalHeader>
                <ModalBody>
                    {pricing[period].price === '-' ? 
                    <div
                    className="p-4 py-6 rounded-lg bg-gray-50 dark:bg-gray-800 md:p-8">
                    <form onSubmit={(e) => handleSubmit(e)}>
                      <div className="-mx-2 md:items-center md:flex">
                        <div className="flex-1 px-2">
                          <label
                            className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                            {t.firstName}
                          </label>
                          <input
                            type="text"
                            name="name"
                            placeholder={t.firstName}
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>
                        <div className="flex-1 px-2 mt-4 md:mt-0">
                          <label
                            className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                            {t.lastName}
                          </label>
                          <input
                            type="text"
                            name="lastname"
                            placeholder={t.lastName}
                            className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          />
                        </div>
                      </div>
                      <div className="mt-4">
                        <label
                          className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                          Email
                        </label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className="block w-full px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                        />
                      </div>
                      <div className="w-full mt-4">
                        <label
                          className="block mb-2 text-sm text-gray-600 dark:text-gray-200">
                          {t.message}
                        </label>
                        <textarea
                          name="message"
                          className="block w-full h-32 px-5 py-2.5 mt-2 text-gray-700 placeholder-gray-400 bg-white border border-gray-200 rounded-lg md:h-56 dark:placeholder-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:border-gray-700 focus:border-blue-400 dark:focus:border-blue-400 focus:ring-blue-400 focus:outline-none focus:ring focus:ring-opacity-40"
                          placeholder={t.message}
                          defaultValue={t.pricingPage.aboutPlan.replace('%s', (Utils.nestedObjectString(t, name) || name))}
                        />
                      </div>
                      <button
                        className="w-full px-6 py-3 mt-4 text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-blue-500 rounded-lg hover:bg-blue-400 focus:outline-none focus:ring focus:ring-blue-300 focus:ring-opacity-50">
                        {t.aboutUsPage.button}
                      </button>
                    </form>
                  </div>
                  : 
                    <ul role="list" className="mb-8 space-y-4 text-left">
                        {details.map(detail => 
                            <li className="flex items-center space-x-3">
                                <PiSealCheckDuotone fontSize={24}
                                                    className="flex-shrink-0  text-green-500 dark:text-green-400"/>
                                <span>{Utils.nestedObjectString(t,detail) || detail}</span>
                            </li>
                        )}
                    </ul>}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" variant="light" onPress={onClose}>
                    {t.close}
                    </Button>
                </ModalFooter>
                </>
            )}
            </ModalContent>
        </Modal>
        </>
    )
}

export default PricingCard