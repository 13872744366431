const userPlans = {
    crypto: {
        key: 'crypto',
        name: 'cryptoPage.crypto',
        description: '',
        image: '/plans/crypto.svg',
        pricing: {
            months: {
                price: 200,
                originalPrice: 250,
            },
            year: {
                price: 600,
                originalPrice: 750,
            },
        },
        allowedPaths: [
            "/analysis/bitcoin",
            "/analysis/ethereum",
            "/analysis/total-indices",
            "/analysis/dom-indices",
            "/analysis/alt-coin",
            "/analysis/on-chain",
            "/analysis/blockchain/alarms",
        ],
        items: {
            "tr": ['Kripto Analizleri', 'Endeksler, Altcoinler ve Dominanslar', 'Uzun - Kısa vade Teknik analiz', 'Temel Analiz', 'On Chain Analiz', 'Alarmlar'],
            "en": ['Crypto Analysis', 'Indices, Alt Coins and Dominances', 'Long - Short term technical analysis', 'Base Analysis', 'On Chain Analysis', 'Alarms'],
        },
        details: ['cryptoPage.bitcoin', 'cryptoPage.ethereum', 'cryptoPage.totalIndices', 'cryptoPage.domIndices', 'cryptoPage.subCoins', 'cryptoPage.onChain', 'alarms']
    },
    forex: {
        key: 'forex',
        name: 'analysisPage.mainForex',
        description: '',
        image: '/plans/forex.svg',
        pricing: {
            months: {
                price: 200,
                originalPrice: 250,
            },
            year: {
                price: 600,
                originalPrice: 750,
            },
        },
        allowedPaths: [
            "/analysis/sp500",
            "/analysis/nasdaq",
            "/analysis/dax",
            "/analysis/dxy",
            "/analysis/eur-usd",
            "/analysis/usd-jpy",
            "/analysis/gold",
            "/analysis/silver",
            "/analysis/oil",
            "/analysis/forex/alarms",
          ],
        items: {
            "tr": ['9 Major Forex Piyasası', 'Uzun - Kısa vade Teknik analiz', 'Temel Analiz', 'Alarmlar'],
            "en": ['9 Major Forex Markets', 'Long - Short term technical analysis', 'Base Analysis', 'Alarms'],
        },
        details: ["Sp500","Nasdaq","Dax","Dxy","Eur/Usd","Usd/Jpy","forexPage.gold","forexPage.silver","forexPage.oil", 'alarms']
    },
    partities: {
        key: 'partities',
        name: 'analysisPage.partities',
        description: '',
        image: '/plans/partities.svg',
        pricing: {
            months: {
                price: 200,
                originalPrice: 250,
            },
            year: {
                price: 600,
                originalPrice: 750,
            },
        },
        allowedPaths: [
            "/analysis/aud-usd",
            "/analysis/gbp-usd",
            "/analysis/nzd-usd",
            "/analysis/usd-cad",
            "/analysis/usd-chf",
            "/analysis/eur-jpy",
            "/analysis/gbp-jpy",
          ],
        items: {
            "tr": ['7 Major Parite', 'Uzun - Kısa vade Teknik analiz', 'Temel Analiz', 'Alarmlar'],
            "en": ['7 Major Parities', 'Long - Short term technical analysis', 'Base Analysis', 'Alarms'],
        },
        details: ["Aud/Usd","Gbp/Usd","Nzd/Usd","Usd/Cad","Usd/Chf","Eur/Jpy","Gbp/Jpy"]
    },
    stockMarket: {
        key: 'stockMarket',
        name: 'analysisPage.stockMarket',
        description: '',
        image: '/plans/stockMarket.svg',
        pricing: {
            months: {
                price: 400,
                originalPrice: 500,
            },
            year: {
                price: 1200,
                originalPrice: 1500,
            },
        },
        allowedPaths: [
            "/analysis/china50",
            "/analysis/hongkong50",
            "/analysis/nse",
            "/analysis/nikkei",
            "/analysis/cac40",
            "/analysis/dax",
            "/analysis/stoxx50",
            "/analysis/ftse",
            "/analysis/smi",
            "/analysis/dow-jones",
            "/analysis/nasdaq",
            "/analysis/russell2000",
            "/analysis/sp500",
          ],
        items: {
            "tr": ['ABD Borsaları (DowJones, Nasdaq, Sp500, Russell200)', 'Avrupa Borsaları (Almanya, Fransa, İngiltere, İsviçre, EuroStoxx)', 'Asya Borsaları (Çin, HongKong, Hindistan, Japonya)', 'Uzun - Kısa vade Teknik analiz', 'Temel Analiz', 'Alarmlar'],
            "en": ['US Stock Exchanges (Dow Jones, Nasdaq, Sp500, Russell 200)', 'European Stock Exchanges (Germany, France, UK, Switzerland, EuroStoxx)', 'Asian Stock Exchanges (China, HongKong, India, Japan)', 'Long - Short term technical analysis', 'Base Analysis', 'Alarms'],
        },
        details: ["China50","Hongkong50 (HongKong)","analysisPage.nseIndia","analysisPage.nikkeiJapan","analysisPage.cac40France","analysisPage.daxGermany","Stoxx50 (Euro)","analysisPage.ftseLondon","analysisPage.smiSwiss","Nasdaq","Russell2000","Sp500"]
    },
    corporateServices: {
        key: 'corporateServices',
        name: 'analysisPage.corporateServices',
        description: 'analysisPage.contact',
        image: '/plans/corporateServices.svg',
        pricing: {
            months: {
                price: 0,
                originalPrice: 0,
            },
            year: {
                price: 0,
                originalPrice: 0,
            },
        },
        allowedPaths: [
            "/analysis/coffee",
            "/analysis/cotton",
            "/analysis/live-cattle",
            "/analysis/orange-juice",
            "/analysis/corn",
            "/analysis/soybean",
            "/analysis/sugar",
            "/analysis/wheat",
            "/analysis/energy-etf",
            "/analysis/natgas",
            "/analysis/gasoline",
            "/analysis/copper",
            "/analysis/aluminum",
            "/analysis/nickel",
            "/analysis/paladium",
            "/analysis/platinium",
            "/analysis/aud-usd",
            "/analysis/gbp-usd",
            "/analysis/nzd-usd",
            "/analysis/usd-cad",
            "/analysis/usd-chf",
            "/analysis/china50",
            "/analysis/hangseng50",
            "/analysis/nse",
            "/analysis/nikkei",
            "/analysis/cac40",
            "/analysis/dax",
            "/analysis/stoxx50",
            "/analysis/ftse",
            "/analysis/smi",
            "/analysis/down-jones",
            "/analysis/nasdaq",
            "/analysis/russell2000",
            "/analysis/sp500",
          ],
        items: {
            "tr": [],
            "en": [],
        },
        details: []
    }
};
export default userPlans;